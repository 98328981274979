import React from 'react';
import './App.css';
import EgirlFinder from './egirl';
import DataPage from './DataPage';
import PaymentComplete from './PaymentComplete'; // Import the new component
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { useState, useEffect } from "react";

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

function App() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/data" component={DataPage} />
          <Route path="/payment-complete" component={PaymentComplete} /> {/* New Route */}
          <Route path="/" exact>
            {message ? <Message message={message} /> : <EgirlFinder />}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
