import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import './faq.css'
const determine_discount = (selected_num, totalEgirls) => {
  
    if (selected_num === "All") {
        if (totalEgirls > 1000) return 80;
        if (totalEgirls > 500) return 70;
        if (totalEgirls > 250) return 60;
        if (totalEgirls > 100) return 50;
        if (totalEgirls > 50) return 30;
        if (totalEgirls > 25) return 25;
        if (totalEgirls > 15) return 15;
        if (totalEgirls > 10) return 10;
        if (totalEgirls > 5) return 5;
        return 0;
    }
    const num = parseInt(selected_num, 10);
    const discountMapping = {
        0: 0,
        5: 0,
        10: 5,
        15: 10,
        25: 15,
        50: 25,
        100: 25,
        250: 50,
        500: 60,
        1000: 70
    };
    return discountMapping[num] || 0;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeForm = () => {
  return (
      <form>
          <CardElement />
          {/* Add any other fields if required */}
      </form>
  );
};

async function startCheckout(totalPrice, activeRegion, activeRank, activeChampion, selectedNumber) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      totalPrice: totalPrice,
      activeRegion: activeRegion,
      activeRank: activeRank,
      activeChampion: activeChampion,
      selectedNumber: selectedNumber
    }),
  });
  const session = await response.json();
  if (session.url) {
    // Redirect the user to the Stripe Checkout page
    window.location.href = session.url;
  } else {
    console.error('Failed to create checkout session');
  }
}


const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
}

const EgirlFinder = (props) => {
  const [fadeClass, setFadeClass] = useState("fadeIn");
  const [totalEgirls, setTotalEgirls] = useState('-');
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [girlImageIndex, setGirlImageIndex] = useState(0);
  const [skinImageIndex, setSkinImageIndex] = useState(0);

  const regions = ["na", "euw", "eune", "oce", "kr", "jp", "br", "las", "lan", "ru", "tr", "sg", "phl", "tw", "vn", "th"];
  const ranks = ["Iron", "Bronze", "Silver", "Gold", "Platinum", "Emerald", "Diamond", "Master", "Grandmaster", "Challenger"];
  const champions = ["Ahri", "Amumu", "Annie", "Janna", "Jinx", "Katarina", "Karma", "Lulu", "Lux", "Milio", "MissFortune", "Morgana", "Nami", "Neeko", "Orianna", "Senna", "Seraphine", "Sona", "Soraka", "Yuumi", "Zoe", "Zyra"];

  const [activeRegion, setActiveRegion] = useState(null);
  const [activeRank, setActiveRank] = useState(null);
  const [activeChampion, setActiveChampion] = useState(null);

  const dividingLineRef = useRef(null);
  const backgroundBelowLineRef = useRef(null);

  useEffect(() => {
    const adjustVerticalLine = () => {
        const dividingLine = dividingLineRef.current;
        const backgroundBelowLine = backgroundBelowLineRef.current;
        const verticalDividingLine = document.querySelector('.vertical-dividing-line');

        if (dividingLine && backgroundBelowLine && verticalDividingLine) {
            const dividingLineBottom = dividingLine.getBoundingClientRect().bottom;
            const backgroundBottom = backgroundBelowLine.getBoundingClientRect().bottom;

            verticalDividingLine.style.top = `${dividingLineBottom}px`;
            verticalDividingLine.style.height = `${backgroundBottom - dividingLineBottom}px`;
        }
    };

    const handleResize = () => {
        requestAnimationFrame(adjustVerticalLine);
    };

 

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);


  useEffect(() => {
      if (activeRegion) {
          fetch(`${process.env.REACT_APP_SEARCH_URL}/search?region=${activeRegion}&rank=${activeRank || ""}&championMain=${activeChampion || ""}`)
              .then(response => response.json())
              .then(data => {
                  setTotalEgirls(data.data);
              })
              .catch(error => {
                  console.error("Error fetching data:", error);
              });
      } else {
          setTotalEgirls('-');
      }
  }, [activeRegion, activeRank, activeChampion]);

  useEffect(() => {
    const interval = setInterval(() => {
        const nextSkinImageIndex = (skinImageIndex + 1) % 12;
        
        // Preload the next skin image
        preloadImage(`/Assets/skins/image${nextSkinImageIndex}.jpg`);

        // Start by fading out the current images
        setFadeClass("fadeOut");
        
        setTimeout(() => {
            // Update the image sources while the old images are still fading out
            setGirlImageIndex((prevIndex) => (prevIndex + 1) % 12);
            setSkinImageIndex(nextSkinImageIndex);
            // Begin fading in the new images while the old ones are still fading out
            setFadeClass("fadeIn");
        }, 600);  // This duration determines how much overlap there is. Adjust as needed.
    }, 8000);

    return () => clearInterval(interval);
  }, [girlImageIndex, skinImageIndex]);

  const handleRegionClick = (region) => setActiveRegion(prevRegion => prevRegion === region ? null : region);
  const handleRankClick = (rank) => setActiveRank(prevRank => prevRank === rank ? null : rank);
  const handleChampionClick = (champion) => setActiveChampion(prevChampion => prevChampion === champion ? null : champion);

  return (
      <div className="container">
          <div className="main-content-wrapper">
              <img 
                  src={girlImageIndex === 0 ? "/Assets/girls/3c7eaf005566b3a90db06339f9755d03_edit_211448458330235.jpg" : `/Assets/girls/${girlImageIndex === 0 ? "3c7eaf005566b3a90db06339f9755d03_edit_211448458330235" : "image" + girlImageIndex}.jpg`} 
                  onError={(e) => { e.target.onerror = null; setGirlImageIndex(girlImageIndex + 1); }} 
                  alt="Girl" 
                  className={`left-image ${fadeClass}`} 
              />
              <div className="content">
                  <h1 className="title">egirlfinder.lol</h1>
                  <p className="subtitle">collect the summoner names of thousands of egirls</p>
                  <h2>Select Region</h2>
                  <div className="button-container rank-buttons">
                      {regions.map((region, index) => (
                          <button key={index} title={region} 
                          className={`circle-button ${activeRegion === region ? "active" : ""}`} 
                          style={{ backgroundImage: `url(/Assets/regions/${region}.png)` }} 
                          onClick={() => handleRegionClick(region)}></button>
                      ))}
                  </div>
                  
                  <h2>Select Rank (optional)</h2>
                  <div className="button-container rank-buttons">
                      {ranks.map((rank, index) => (
                          <button key={index} title={rank} 
                          className={`circle-button ${activeRank === rank ? "active" : ""} ranked-circle-button`} 
                          style={{ backgroundImage: `url(/Assets/RankedEmblems/${rank}.png)` }} 
                          onClick={() => handleRankClick(rank)}></button>
                      ))}
                  </div>
                  <p className="note">Note: leaving this blank will randomise rank</p>
                  
                  <h2>Select Champion Main (optional)</h2>
                  <div className="button-container rank-buttons">
                      {champions.map((champion, index) => (
                          <button key={index} title={champion} 
                          className={`circle-button ${activeChampion === champion ? "active" : ""}`} 
                          style={{ backgroundImage: `url(/Assets/champions/${champion}.png)` }} 
                          onClick={() => handleChampionClick(champion)}></button>
                      ))}
                  </div>
                  
                  <div className="egirls-selection-container">
                      <div>
                          <h2>Select Number of egirls</h2>
                          
                          <select 
                              value={selectedNumber} 
                              onChange={e => setSelectedNumber(e.target.value)}
                          >
                              <option value={0}>0</option>
                              {[5, 10, 15, 25, 50, 100, 250, 500, 1000].map((num) => {
                                  if (totalEgirls >= num) {
                                      return <option key={num} value={num}>{num} ({determine_discount(num, totalEgirls)}% off)</option>;
                                  }
                                  return null;
                              })}
                              {totalEgirls > 0 && <option value="All">All ({totalEgirls}) ({determine_discount("All", totalEgirls)}% off)</option>}
                          </select>
                      </div>
                      
                      <div className="total-search-box">
                          Total egirls for the search: {totalEgirls}
                      </div>
                  </div>

                  <div className="cart">
                      <div className="cart-item">
                          <span>{selectedNumber} egirls <span className="discount-text">{determine_discount(selectedNumber, totalEgirls) > 0 ? `(${determine_discount(selectedNumber, totalEgirls)}% off)` : ""}</span></span>
                          <span>${((selectedNumber === "All" ? totalEgirls : selectedNumber) * 0.04 * (1 - determine_discount(selectedNumber)/100)).toFixed(2)}</span>
                      </div>
                      
                      
                      <div className="cart-addons">
                          <div className="addon">
                              <span className="rank">{activeRank ? `Rank (${activeRank})` : "Rank"}</span>
                              <span>{activeRank ? "$1" : "-"}</span>
                          </div>
                          
                          <div className="addon">
                              <span className="champion-main">{activeChampion ? `Champion Main (${activeChampion})` : "Champion Main"}</span>
                              <span>{activeChampion ? "$1" : "-"}</span>
                          </div>
                      </div>

                      <div className="total">
                          <span>Total:</span>
                          <span>${(((selectedNumber === "All" ? totalEgirls : selectedNumber) * 0.04 * (1 - determine_discount(selectedNumber)/100) + (activeRank ? 1 : 0) + (activeChampion ? 1 : 0))).toFixed(2)}</span>
                      </div>
                      <button 
                          className="proceed-button stripe-button" 
                          onClick={() => { 
                              const totalPrice = (((selectedNumber === "All" ? totalEgirls : selectedNumber) * 0.04 * (1 - determine_discount(selectedNumber)/100) + (activeRank ? 1 : 0) + (activeChampion ? 1 : 0))).toFixed(2);
                              startCheckout(totalPrice, activeRegion, activeRank, activeChampion, selectedNumber === "All" ? totalEgirls : selectedNumber);
                          }}
                          disabled={!(activeRegion && (selectedNumber === "All" ? totalEgirls : selectedNumber) > 0)}
                          style={{ opacity: !(activeRegion && (selectedNumber === "All" ? totalEgirls : selectedNumber) > 0) ? 0.5 : 1 }}
                      >
                          Checkout with <img src="/Assets/stripe/stripe.png" alt="Stripe Logo" className="stripe-logo"/>
                          </button>
                      <img src="Assets/stripe/paymentmethods.png" alt="Payment Methods" className="payment-methods-image" />
                  </div>
              </div>
              <img 
                  src={`/Assets/skins/image${skinImageIndex}.jpg`} 
                  onError={(e) => { e.target.onerror = null; setSkinImageIndex(skinImageIndex + 1); }} 
                  alt="Skin" 
                  className={`right-image ${fadeClass}`} 
              />
              
          </div>
          {/* New Database Update Div */}
          <div className="database-update">
              Database last updated: 4 October 2023
          </div>

          {/* Dividing Line */}
          <div className="dividing-line" ref={dividingLineRef}></div>


          <div 
          
          ></div>

          <div className="background-below-line" ref={backgroundBelowLineRef}>
  
          <div className="left-container">
    <h2 className="section-title">FAQ</h2>
    <div className="faq-item">
        <p className="question">How does it work?</p>
        <p className="answer">
            I run an algorithm on every ranked player in each server to determine if that player is an 'egirl'. 
            I take things into account like the champions played, summoner name, summoner icon etc, and if the score is higher enough I label that summoner as an egirl.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
        <p className="question">What do you mean by champion main and rank?</p>
        <p className="answer">
            Champion main refers to highest mastery points, and rank refers to SoloQ rank, 
            both calculated at the time of the most recent database update.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
        <p className="question">Can I buy egirls that only play norms/ARAM/flex?</p>
        <p className="answer">
            Currently no, however if there was enough interest I could easily develop this.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
        <p className="question">Are all my purchased egirls guaranteed to be girls?</p>
        <p className="answer">
            No, they aren't all guaranteed to be girls. I estimate between 70-80% of the egirls to be girls, 
            and considering only about 3.5% of all ranked players are considered an egirl this is pretty good.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
        <p className="question">Why doesn't a purchased name exist when I try to add?</p>
        <p className="answer">
            If this happened it means that they changed their summoner name between the last retrieval date and now. 
            Contact me at oliver@egirlfinder.lol and I'll replace these names for you.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
        <p className="question">How frequently is the egirl database updated?</p>
        <p className="answer">
            Currently weekly, but if there's enough interest I could update it more often.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
    </div>
    <div className="faq-item">
        <p className="question">How is my order delivered?</p>
        <p className="answer">
            Via the email you provide in checkout.
        </p>
        <div className="thin-line"></div>
    </div>
    <div className="faq-item">
    </div>
</div>

<div className="right-container">
    <h2 className="section-title">About Me</h2>
    <p className="about-text">Hi! I am a software engineering graduate in Australia who made this project for fun.
    <br></br>
    <br></br>
  Looking to make some more projects like this, if anyone is looking for a dev / have any business enquiries about this project feel free to reach out :)
  <br></br>
  <br></br>
  For feature suggestions or other questions/issues contact me through my email as well.
  <br></br>
    <br></br>
    
 contact: oliver@egirlfinder.lol
</p>
</div>


    <div className="vertical-dividing-line"></div> {/* This is the new line for the vertical divider */}
</div>

          {/* Moved disclaimer out of the background-below-line div */}
          <div className="disclaimer">
              This website isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends.
              League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
          </div>
      </div>
  )
}

export default EgirlFinder;
