import React from 'react';
import './PaymentComplete.css';

function PaymentComplete() {
    return (
      <div className="payment-complete">
        <div className="payment-complete-header">
          <img src="/Assets/paymentpage/1.jpg" alt="Thank you image" className="payment-complete-image soft image-1" />
          <div className="payment-complete-text">
            <h2>Thank you for your purchase!</h2>
            <p>Your order has been emailed to you.</p>
          </div>
          <img src="/Assets/paymentpage/2.jpg" alt="Order emailed image" className="payment-complete-image soft image-2" />
        </div>
        <img src="/Assets/paymentpage/3.png" alt="Heart image" className="payment-complete-image heart-image" style={{ maxWidth: '100%' }} />
        <div className="payment-complete-text-content">
          <p>This project was crafted with passion by me. If you have suggestions, queries, or any feedback, kindly reach out at EMAIL.</p>
          <p>Currently, I am exploring opportunities in software development. If you are interested in collaborating or hiring, I'd be pleased to connect with you.</p>
        </div>
        <div className="payment-complete-disclaimer">
          This website isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends.
          League of Legends and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
        </div>
      </div>
    );
}

export default PaymentComplete;
